import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
  dividerWidth: {
    '@media (max-width: 960px)': {
      display: 'none',
    },
    width: '2px',
  },
  sideNavContainer: {
    '@media (max-width: 960px)': {
      display: 'none',
    },
    position: 'relative',
  },
  paperContainer: {
    width: '100%',
  },
  paperContextWrapper: {
    margin: '0 auto',
    '@media (max-width: 960px)': {
      fontSize: '1.3em',
      maxWidth: '80%',
    },
  },
  paperContext: {
    textAlign: 'center',
    '@media (min-width: 960px)': {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  paperTitle: {
    fontSize: '1.7em',
    '@media (max-width: 960px)': {
      fontSize: '1.3em',
    },
  },
  authorName: {
    fontSize: '1.1em',
    '@media (max-width: 960px)': {
      fontSize: '0.9em',
    },
  },
  affiliationName: {
    fontSize: '1.1em',
    '@media (max-width: 960px)': {
      fontSize: '0.9em',
    },
  },
  affiliation: {
    '@media (min-width: 960px)': {
      'paddingLeft': '0.5em',
      'paddingRight': '0.5em',
    },
    '@media (max-width: 960px)': {
      'display': 'block',
    },
  },
  conferenceName: {
    fontSize: '1.2em',
    '@media (max-width: 960px)': {
      fontSize: '0.9em',
    },
  },
  authorLink: {
    'color': '#0a7b83',
    'whiteSpace': 'nowrap',
  },
  linkStyle: {
    "&:hover": {
      textDecoration: "none"
    }
  },
  buttonStyle: {
    marginTop: "0.5em",
    marginRight: "0.5em",
    padding: "3px 9px"
  },
  buttonPaperStyle: {
    marginTop: "0.5em",
    marginRight: "0.5em",
    color: "#0a7b83",
    borderColor: "#0a7b83",
    padding: "3px 9px",
    "&:hover": {
      borderColor: "#0a7b83",
      Color: "#0a7b83",
    },
  },
  buttonVideoStyle: {
    marginTop: "0.5em",
    marginRight: "0.5em",
    color: "#FF2A00",
    borderColor: "#FF0000",
    padding: "3px 9px",
    "&:hover": {
      borderColor: "#FF0000",
      color: "#FF2A00",
    },
  },
  subtitle: {
    fontSize: '1.6em',
    '@media (max-width: 960px)': {
      fontSize: '1.2em',
    },
  },
  caption: {
    '@media (min-width: 960px)': {
      marginBottom: '-2em',
      fontSize: '1.0em',
    },
    marginTop: '2em',
    fontSize: '0.8em',
  },
  content: {
    '@media (max-width: 960px)': {
      fontSize: '0.9rem',
    },
    textAlign: 'left',
  },
  imageStyle: {
    objectFit: 'contain',
    '@media (min-width: 960px)': {
      padding: '2em',
      width: '80%',
    },
    '@media (max-width: 960px)': {
      padding: '1em 0 0 0',
      width: '100%',
    },
  },
  videoStyle: {
    objectFit: 'contain',
    '@media (min-width: 960px)': {
      padding: '1em',
      width: '90%',
    },
    '@media (max-width: 960px)': {
      padding: '1em 0 0 0',
      width: '100%',
    },
  },
  dividerStyle: {
    margin: '1em',
    backgroundImage: 'linear-gradient(to right, rgba(10, 123, 131, 0.1), rgba(10, 123, 131, 0.75), rgba(10, 123, 131, 0.1))'
  },
  videoContainerStyle: {
    margin: '0 auto',
    marginTop: '1em',
    position: 'relative',
    overflow: 'hidden',
    width: '90%',
    paddingTop: '50.625%',
  },
  videoIframe: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
  },
  citationContainerStyle: {
    position: 'relative',
    margin: '0',
    padding: '0',
  },
  citationCopyButtonStyle: {
    position: 'absolute',
    top: '0px',
    right: '20px',
    color: 'black',
  },
  citationStyle:{
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textAlign: 'left',
    fontSize: '0.9em',
    margin: '1em',
    padding: '1em',
    backgroundColor: 'lightgray',
    border: '2px solid lightgray',
    borderRadius: '8px',
    '@media (max-width: 960px)': {
      fontSize: '0.8em',
    },
  }
});

export type Styles = WithStyles<typeof styles>;

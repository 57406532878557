import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import SideNavPub from '../../components/SideNavPub';
import { Styles, styles } from './RelightableHandsContainer.styles';

class RelightableHandsContainer extends React.Component<Styles> {

  componentDidMount = () => {
    document.title = 'RelightableHands: Efficient Neural Relighting of Articulated Hand Models';
  }

  render = () => {
    const {
      classes,
    } = this.props;

    const citation = `@InProceedings{Iwase_2023,
  author = {Iwase, Shun and, Saito, Saito and Simon, Tomas and Lombardi, Stephen and Timur, Bagautdinov and Joshi, Rohan and Prada, Fabian and Shiratori, Takaaki and Sheikh, Yaser and Saragih, Jason},
  title = {RelightableHands: Efficient Neural Relighting of Articulated Hand Models},
  booktitle = {CVPR},
  year = {2023}
}`

    const onClickCopyButton = () => {
      navigator.clipboard.writeText(citation);
    }

    return (
      <Paper elevation={0}>
        <Grid item xs container className={classes.paperContainer}>
          <Grid item md={12} lg={12} xl={12} className={classes.sideNavContainer}>
            <SideNavPub />
          </Grid>
          <Grid item md={8} lg={6} xl={4} className={classes.paperContextWrapper}>
            <div className={classes.paperContext}>
              <Typography className={classes.paperTitle}>
                RelightableHands: Efficient Neural Relighting of Articulated Hand Models
              </Typography>
              <Typography className={classes.authorName}>
                <Link className={classes.authorLink} href="https://www.sh8.io" target="_blank">Shun Iwase<sup>1,2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://shunsukesaito.github.io/" target="_blank">Shunsuke Saito<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="http://www.cs.cmu.edu/~tsimon/" target="_blank">Tomas Simon<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://stephenlombardi.github.io/" target="_blank">Stephen Lombardi<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://scholar.google.ch/citations?user=oLi7xJ0AAAAJ&hl=en" target="_blank">Timur Bagautdinov<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.linkedin.com/in/rohan-m-joshi/" target="_blank">Rohan Joshi<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.cs.jhu.edu/~fpradan1/" target="_blank">Fabian Prada<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://sites.google.com/view/takaaki-shiratori/home" target="_blank">Takaaki Shiratori<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="http://www.cs.cmu.edu/~yaser/" target="_blank">Yaser Sheikh<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://scholar.google.com/citations?user=ss-IvjMAAAAJ&hl=en" target="_blank">Jason Saragih<sup>2</sup></Link>&emsp;
              </Typography>
              <Typography className={classes.affiliationName}>
                <span className={classes.affiliation}><sup>1</sup>Carnegie Mellon University</span>
                <span className={classes.affiliation}><sup>2</sup>Reality Labs Research at Meta</span>
              </Typography>
              <Typography className={classes.conferenceName}>
                <i>CVPR 2023</i>
              </Typography>
              <Link href="https://arxiv.org/abs/2302.04866" className={classes.linkStyle} target="_blank">
                <Button variant="outlined" className={classes.buttonPaperStyle} color="primary">
                  Paper
                </Button>
              </Link>
              <Link href="https://youtu.be/GGt0R6vn0g8" className={classes.linkStyle} target="_blank"> 
                <Button variant="outlined" className={classes.buttonVideoStyle} color="primary">
                  Full Presentation
                </Button>
              </Link>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Abstract
              </Typography>
              <Grid item>
                <Typography className={classes.content}>
                We present the first neural relighting approach for rendering high-fidelity personalized hands that can be animated in real-time under novel illumination. Our approach adopts a teacher-student framework, where the teacher learns appearance under a single point light from images captured in a light-stage, allowing us to synthesize hands in arbitrary illuminations but with heavy compute. Using images rendered by the teacher model as training data, an efficient student model directly predicts appearance under natural illuminations in real-time. To achieve generalization, we condition the student model with physics-inspired illumination features such as visibility, diffuse shading, and specular reflections computed on a coarse proxy geometry, maintaining a small computational overhead. Our key insight is that these features have strong correlation with subsequent global light transport effects, which proves sufficient as conditioning data for the neural relighting network. Moreover, in contrast to bottleneck illumination conditioning, these features are spatially aligned based on underlying geometry, leading to better generalization to unseen illuminations and poses. In our experiments, we demonstrate the efficacy of our illumination feature representations, outperforming baseline approaches. We also show that our approach can photorealistically relight two interacting hands at real-time speeds.
                </Typography>
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Overview
              </Typography>
              <Typography className={classes.content}>
                 Our method adopts a teacher-student framework. First, the teacher model learns appearance under a single point light from images captured in a light-stage. The teacher models is generalizable to arbitrary illuminations, however, the rendering speed is slow because of OLAT-based relighting for 512 lights. In contrast, the student model directly leverages whole environment map information to extract spatially-aligned visibility-aware illumination features via ray tracing for real-time rendering.

              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='teacher_student.img'
                  src={require('../../assets/relightable_hands/teacher_student.png')}
                />
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Teacher Model
              </Typography>
              <Typography className={classes.content}>
                We adopt OLAT-based relighting for the teacher model to achieve generalizable rendering. First, OLAT textures for all the lights are decoded with the U-Netfrom the joint and local light/view direction features. Then, the weighted sum based on the light intensities is computed, and a final prediction is rendered via differentiable ray marching.
              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='teacher_overview.img'
                  src={require('../../assets/relightable_hands/teacher_overview.png')}
                />
              </Grid>

              <Typography className={classes.content}>
                The teacher model videos below demonstrate high-fidility photorealitic rendering under various lighting conditions such as directional light, near-field light, and environment map.
              </Typography>

              <Grid item>
                <video className={classes.videoStyle} controls >
                  <source src={require('../../assets/relightable_hands/teacher_video.mp4')} type="video/mp4"/>
                </video>
              </Grid>

              <Typography className={classes.content}>
                Here, we compare our method against the physically-based rendering method, GGX. This flip test shows the parameterization of GGX is unable to represent complex transmissive effects faithfully. (Full-screen gives better visibility of differences.)
              </Typography>

              <Grid item>
                <video className={classes.videoStyle} controls >
                  <source src={require('../../assets/relightable_hands/teacher_ggx.mp4')} type="video/mp4"/>
                </video>
              </Grid>

              <Typography className={classes.content}>
                Further, we ablate our method with and without a visibility map. This video shows that the visibility information helps generate physically-valid hard shadows accurately.
              </Typography>

              <Grid item>
                <video className={classes.videoStyle} controls >
                  <source src={require('../../assets/relightable_hands/teacher_visiblity.mp4')} type="video/mp4"/>
                </video>
              </Grid>

              <Typography className={classes.content}>
                However, the runtime of the teacher model under natural illumination is slow (30s). Toward real-time rendering with high-quality images, we train the student model with the images generated by the teacher model.
              </Typography>
              <Divider className={classes.dividerStyle} />

              <Typography className={classes.subtitle}>
                Student Model
              </Typography>

              <Typography className={classes.content}>
                The figure below gives an overview architecture of the student model. Similar to the teacher model, the joint feature and MVP geometry are first obtained. Next, given a skinned coarse hand mesh and environment map, ray tracing is performed to obtain visibility-aware diffuse and specular features. Finally, the joint feature and visibility-aware diffuse and specular features are given to the texture decoder to predict the final rendering. As mentioned in the teacher model section, the student network is trained to match its output with the corresponding teacher target.
              </Typography>

              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='student_overview.img'
                  src={require('../../assets/relightable_hands/student_overview.png')}
                />
              </Grid>

              <Typography className={classes.content}>
                For real-time rendering, efficient computation of spatially-aligned visibility-aware diffuse/specular features is crucial. To this end, we present a fast illumination feature extraction algorithm (2.7ms) that leverages real-time ray tracing from each vertex on the coarse mesh. If the rays do not hit the other parts of the hand (e.g., green arrows), we sample the light from the corresponding environment map location. Otherwise, the contribution of the ray is regarded as 0. By projecting the sampled lights into the UV space, the spatially aligned diffuse and specular features with visibility are obtained.
              </Typography>

              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='student_overview.img'
                  src={require('../../assets/relightable_hands/student_feature_computation.png')}
                />
              </Grid>

              <Typography className={classes.content}>
                The results of our experiments and ablation study of the student model below clearly illustrates that spatially-aligned representation significantly improves the fidelity of reconstruction while achieving real-time rendering. In contrast to our method, the state-of-the-art model-based relighting method, DRAM, fails to match its overall intensity to the teacher image. 
              </Typography>

              <Grid item>
                <video className={classes.videoStyle} controls >
                  <source src={require('../../assets/relightable_hands/student_video.mp4')} type="video/mp4"/>
                </video>
              </Grid>

              <Divider className={classes.dividerStyle} />

              <Typography className={classes.subtitle}>
                Full Presentation
              </Typography>
              <div className={classes.videoContainerStyle}>
                <Grid item>
                  <iframe className={classes.videoIframe} src="https://www.youtube.com/embed/GGt0R6vn0g8"></iframe>
                </Grid>
              </div>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Citation
              </Typography>
              <div className={classes.citationContainerStyle}>
                <Tooltip title="Copy" placement="left" arrow>
                  <IconButton className={classes.citationCopyButtonStyle} onClick={onClickCopyButton} >
                    <FileCopyOutlinedIcon/>
                  </IconButton>
                </Tooltip>
                <div className={classes.citationStyle}>
                  <pre>
                    {citation}
                  </pre>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(RelightableHandsContainer);

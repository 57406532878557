import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from "react-router-dom";


import navBarData from '../data/navBarPubData.json';
import { Styles, styles } from './NavBarPub.styles';


class NavBarPub extends React.Component<Styles> {
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div>
        <List component="nav" className={classes.container}>
          <ListItem component="div">
            {navBarData.map((data, num) => (
              <ListItemText inset key={num} className={classes.navItem}>
              { data.internal
                ? <RouteLink to={data.link!} className={classes.navText}>
                    {data.content}
                  </RouteLink>
                : <Link href={data.link!} className={classes.navText}>
                    {data.content}
                  </Link>
              }
              </ListItemText>
            ))}
          </ListItem >
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(NavBarPub);

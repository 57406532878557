import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from "react-router-dom";
import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import researchData from '../data/researchData.json';
import { RootStyles, rootStyles } from './ComponentsRoot.styles';
import { Styles, styles } from './Research.styles';

interface Props {
  locale: string;
  updateVisibility(visible: boolean): void;
  updateCurrentContent(value: string): void;
}

class Research extends React.Component<Props & RootStyles & Styles> {
  render = () => {
    const {
      classes,
      locale,
      updateCurrentContent,
      updateVisibility,
    } = this.props;

    const onChange = (isVisible: boolean) => {
      updateVisibility(isVisible);

      if (isVisible) {
        updateCurrentContent(researchData.id);
      }
    };

    const boldText = (num: number, str: string, isBold: boolean, isLast: boolean, isEqual: boolean) => {
      let comma = isLast ? '' : ', ';
      let asterisk = isEqual ? '*' : '';
      if (isBold) {
        return (<span  key={num}><b>{str}{asterisk}</b>{comma}</span>);
      } else {
        return (<span  key={num}>{str}{asterisk}{comma}</span>);
      }
    };

    const researchList = (dict: any) => {
      if (dict.publications.items === undefined) {
        return (
          <Typography className={classes.researchDescription}>Work in Progress...</Typography>
        );
      } else {
        return (
          <div className={classes.researchDescription}>
            {dict.publications.items.map((data: any, num: number) => (
              <div key={num} className={classes.researchBody}>
                <Typography className={classes.paperTitle}>
                  {data.url ? (
                    <Link className={classes.link} href={data.url} target="_blank" rel="noopener">
                      {data.title}
                    </Link>
                  ) : data.title}
                </Typography>
                <Typography>
                {data.authors.map((item: any, itemNum: number) => {
                  let isLast = false;
                  let isEqual = item.isEqual;
                  if (itemNum === (data.authors.length - 1)) {
                    isLast = true;
                  }
                  return boldText(itemNum, item.name, item.isMe, isLast, isEqual)
                })}
                </Typography>
                {data.authors[0].isEqual && 
                  <Typography>
                    <span>* <i>Equal Contribution</i></span>
                  </Typography>
                }
                <Typography><i>{data.conference}</i></Typography>
                {data.projectUrl && 
                  <Link href={data.projectUrl} className={classes.linkStyle} target="_blank">
                    <Button variant="outlined" className={classes.buttonStyle} color="primary">
                      Project
                    </Button>
                  </Link>
                }
                {data.projectIntUrl &&
                  <RouteLink to={data.projectIntUrl} className={classes.linkStyle}>
                    <Button variant="outlined" className={classes.buttonStyle} color="primary">
                      Project
                    </Button>
                  </RouteLink>
                }
                {data.paperUrl && 
                  <Link href={data.paperUrl} className={classes.linkStyle} target="_blank">
                    <Button variant="outlined" className={classes.buttonPaperStyle} color="primary">
                       Paper
                    </Button>
                  </Link>
                }
                {data.videoUrl && 
                  <Link href={data.videoUrl} className={classes.linkStyle} target="_blank">
                    <Button variant="outlined" className={classes.buttonStyle}>
                       Video
                    </Button>
                  </Link>
                }
                {data.codeUrl && 
                  <Link href={data.codeUrl} className={classes.linkStyle} target="_blank">
                    <Button variant="outlined" className={classes.buttonStyle} color="secondary">
                      Code
                    </Button>
                  </Link>
                }
                {data.codeComingSoon && 
                  <Link className={classes.linkStyle} target="_blank">
                    <Button variant="outlined" className={classes.buttonStyle} color="secondary" disabled>
                      Code (Coming Soon!)
                    </Button>
                  </Link>
                }
              </div>
            ))}
          </div>
        );
      }
    };

    const getData = (obj: any, key: string) => {
      return obj[key];
    };

    return (
      <div id={researchData.id} className={classes.contentContainer}>
        <Typography className={classes.contentTitle}>
          {getData(researchData, locale).title}
        </Typography>
        { getData(researchData, locale).publications && (
        <VisibilitySensor onChange={onChange}>
          <div>
            {researchList(getData(researchData, locale))}
          </div>
        </VisibilitySensor>
        )}
      </div>
    );
  }
}

export default withStyles((theme) => ({
  ...rootStyles(theme),
  ...styles(theme),
  }),
  { withTheme: true },
)(Research);

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import newsData from '../data/newsData.json';
import { RootStyles, rootStyles } from './ComponentsRoot.styles';
import { Styles, styles } from './News.styles';

interface Props {
  locale: string;
  updateCurrentContent(value: string): void;
  updateVisibility(visible: boolean): void;
}

class News extends React.Component<Props & RootStyles & Styles> {
  render = () => {
    const {
      classes,
      locale,
    } = this.props;

    const getData = (obj: any, key: string) => {
      return obj[key];
    }

    return (
      <div id={newsData.id} className={classes.contentContainer}>
        <Typography className={classes.contentTitle}>
          {getData(newsData, locale).title}
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={1} md={12}/>
          <Grid item xs={12} sm={11} md={12}>
            <div className={classes.news}>
              <List className={classes.list}>
                {getData(newsData, locale).data.map((data: any, num: number) => (
                  <ListItem dense={true} className={classes.listItem} key={num}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Icon className={classes.listItemIconIcon}>circle</Icon>
                    </ListItemIcon>
                    <ListItemText
                        primary={data.title}
                        color="textSecondary"
                        classes={{primary: classes.listItemText}}
                        key={num} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles((theme) => ({
  ...rootStyles(theme),
  ...styles(theme),
  }),
  { withTheme: true },
)(News);

import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { ContentActions } from "../actions";
import MobileNavBarPub from '../components/MobileNavBarPub';
import NavBarPub from '../components/NavBarPub';
import { AppState } from "../store";
import { Styles, styles } from './NavBarContainer.styles';

export interface Handlers {
  handleMobileMenuPub(isOpen: boolean): void;
}

interface Props {
  isMobileMenuPubOpen: boolean;
}

class NavBarPubContainer extends React.Component<Handlers & Props & Styles> {
  render = () => {
    const {
      handleMobileMenuPub,
      isMobileMenuPubOpen,
    } = this.props;

    return (
      <div>
        <NavBarPub />
        <MobileNavBarPub
          handleMobileMenuPub={handleMobileMenuPub}
          isMobileMenuPubOpen={isMobileMenuPubOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = (appState: AppState) => {
  return {
    isMobileMenuPubOpen: appState.state.isMobileMenuPubOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): Handlers => ({
  handleMobileMenuPub: (isOpen: boolean) => {
    dispatch(ContentActions.openMobileMenuPub(isOpen));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBarPubContainer));

import * as React from 'react';
import { withRouter } from 'react-router-dom';
import {RouteComponentProps} from "react-router";

interface Props {
  location: string;
}

class ScrollToTop extends React.Component<Props & RouteComponentProps> {

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  };

  render = () => {
    return this.props.children
  }
}

export default withRouter(ScrollToTop);

import * as React from 'react';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import { Link as RouteLink } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { Styles, styles } from './SideNavPub.styles';
import navBarPubData from '../data/navBarPubData.json';

class SideNavPub extends React.Component<Styles> {
  readonly state = {
    scrollPosition: this.getScrollPosition(),
    showNav: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    let show = false;
    if (winScroll > 60) {
      show = true
    }

    this.setState({
      scrollPosition: winScroll,
      showNav: show,
    })
  };

  getScrollPosition() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.sideNavContext}>
        <Slide direction='right' in={this.state.showNav} timeout={300}>
          <List component="nav">
            {navBarPubData.map((data, num) => (
              <ListItem component="div" key={num}>
                <ListItemText className={classes.navItem}>
                  { data.internal
                    ? <RouteLink to={data.link!} className={classes.itemLink}>
                        {data.content}
                      </RouteLink>
                    : <Link href={data.link!} className={classes.itemLink}>
                        {data.content}
                      </Link>
                  }
                </ListItemText>
              </ListItem >
            ))}
          </List>
        </Slide>
      </div>
    );
  }
}

export default withStyles(styles)(SideNavPub);

import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
  "news": {},
  "list": {},
  "listItem": {
  },
  "listItemText": {
    "fontSize": "1.0em"
  },
  "listItemIcon": {
    "minWidth": "20px"
  },
  "listItemIconIcon": {
    "width": "0.3em",
    "height": "0.3em",
    "fontSize": "0.3em",
    "overflow": "visible"
  }
});

export type Styles = WithStyles<typeof styles>;

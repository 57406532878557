import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import Link from '@material-ui/core/Link';

import { Styles, styles } from './FooterContainer.styles';

class FooterContainer extends React.Component<Styles> {
  render = () => {
    const { classes } = this.props;
    return (
      <Paper className={classes.footerContainer}>
        <Typography className={classes.footerText}>
          Made with ❤︎ by
          <Link href="https://sff1019.github.io/" className={classes.footerLink}> sff1019</Link> and 
          <Link href="https://www.sh8.io" className={classes.footerLink}> sh8</Link>< br/>Copyright 2020-2023.
        </Typography>
      </Paper>
    );
  }
}


export default withStyles(styles)(FooterContainer);

import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { ContentActions } from "../actions";
import About from '../components/About';
import { AppState } from "../store";
import { Styles, styles } from './AboutContainer.styles';

export interface Handlers {
  updateLocale(locale: string): void;
}

interface Props {
  locale: string;
}

class AboutContainer extends React.Component<Handlers & Props & Styles> {

  componentDidMount = () => {
    document.title = 'Shun Iwase, Robotics Institute, Carnegie Mellon University';
  }

  render = () => {
    const {
      locale,
      classes,
    } = this.props;

    return (
      <div id="about" className={classes.container}>
        <About locale={locale}/>
      </div>
    );
  }
}

const mapStateToProps = (appState: AppState) => {
  return {
    locale: appState.state.locale,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): Handlers => ({
  updateLocale: (locale: string) => {
    dispatch(ContentActions.updateLocale(locale));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AboutContainer));

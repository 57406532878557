import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
  mobileToolbar: {
    '@media (min-width: 960px)': {
      visibility: 'hidden',
    },
  },
  translateButton: {
    fontSize: '0.8rem',
  },
});

export type Styles = WithStyles<typeof styles>;

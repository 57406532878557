import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
  researchDescription: {
    '@media (max-width: 1280px)': {
      fontSize: '0.9rem',
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    fontSize: '1rem',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  researchBody: {
    '@media (min-width: 960px)': {
      paddingLeft: theme.spacing(2),
    },
    paddingBottom: theme.spacing(2)
  },
  paperTitle: {
    fontWeight: "bold"
  },
  linkStyle: {
    "&:hover": {
      textDecoration: "none"
    },
    textDecoration: "none"
  },
  buttonStyle: {
    marginTop: "0.5em",
    marginRight: "0.5em",
    padding: "3px 9px"
  },
  buttonPaperStyle: {
    marginTop: "0.5em",
    marginRight: "0.5em",
    color: "#0a7b83",
    borderColor: "#0a7b83",
    padding: "3px 9px",
    "&:hover": {
      borderColor: "#0a7b83",
      Color: "#0a7b83",
    },
  }
});

export type Styles = WithStyles<typeof styles>;

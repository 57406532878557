import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import SideNavPub from '../../components/SideNavPub';
import { Styles, styles } from './RePOSEContainer.styles';

class RePOSEContainer extends React.Component<Styles> {

  componentDidMount = () => {
    document.title = 'RePOSE: Fast 6D Object Pose Refinement via Deep Texture Rendering';
  }

  render = () => {
    const {
      classes,
    } = this.props;

    const citation = `@InProceedings{Iwase_2021_ICCV,
  author = {Iwase, Shun and Liu, Xingyu and Khirodkar, Rawal and Kitani, Kris},
  title = {RePOSE: Fast 6D Object Pose Refinement via Deep Texture Rendering},
  booktitle = {ICCV},
  year = {2021}
}`

    const onClickCopyButton = () => {
      navigator.clipboard.writeText(citation);
    }

    return (
      <Paper elevation={0}>
        <Grid item xs container className={classes.paperContainer}>
          <Grid item sm={12} xs={12} className={classes.sideNavContainer}>
            <SideNavPub />
          </Grid>
          <Grid item md={6} xs className={classes.paperContextWrapper}>
            <div className={classes.paperContext}>
              <Typography className={classes.paperTitle}>
                RePOSE: Fast 6D Object Pose Refinement via Deep Texture Rendering
              </Typography>
              <Typography className={classes.authorName}>
                <Link className={classes.authorLink} href="https://www.sh8.io" target="_blank">Shun Iwase</Link>&emsp;
                <Link className={classes.authorLink} href="https://xingyul.github.io/" target="_blank">Xingyu Liu</Link>&emsp;
                <Link className={classes.authorLink} href="https://rawalkhirodkar.github.io/" target="_blank">Rawal Khirodkar</Link>&emsp;
                <Link className={classes.authorLink} href="https://www.rio.gsic.titech.ac.jp/en/index.html" target="_blank">Rio Yokota</Link>&emsp;
                <Link className={classes.authorLink} href="http://www.cs.cmu.edu/~kkitani/" target="_blank">Kris Kitani</Link>
              </Typography>
              <Typography className={classes.affiliationName}>
                <span className={classes.affiliation}>Carnegie Mellon University</span>
              </Typography>
              <Typography className={classes.conferenceName}>
                <i>ICCV 2021</i>
              </Typography>
              <Link href="https://arxiv.org/abs/2104.00633" className={classes.linkStyle} target="_blank"> 
                <Button variant="outlined" className={classes.buttonPaperStyle} color="primary">
                  Paper
                </Button>
              </Link>
              <Link href="https://github.com/sh8/RePOSE" className={classes.linkStyle} target="_blank"> 
                <Button variant="outlined" className={classes.buttonStyle} color="secondary">
                  Code
                </Button>
              </Link>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Abstract
              </Typography>
              <Grid item>
                <Typography className={classes.content}>
                  We present RePOSE, a fast iterative refinement method for 6D object pose estimation. Prior methods perform refinement by feeding zoomed-in input and rendered RGB images into a CNN and directly regressing an update of a refined pose. Their runtime is slow due to the computational cost of CNN, which is especially prominent in multiple-object pose refinement. To overcome this problem, RePOSE leverages image rendering for fast feature extraction using a 3D model with a learnable texture. We call this deep texture rendering, which uses a shallow multi-layer perceptron to directly regress a view-invariant image representation of an object. Furthermore, we utilize differentiable Levenberg-Marquardt (LM) optimization to refine a pose fast and accurately by minimizing the feature-metric error between the input and rendered image representations without the need of zooming in. These image representations are trained such that differentiable LM optimization converges within few iterations. Consequently, RePOSE runs at 92 FPS and achieves state-of-the-art accuracy of 51.6% on the Occlusion LineMOD dataset - a 4.1% absolute improvement over the prior art, and comparable result on the YCB-Video dataset with a much faster runtime.
                </Typography>
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Proposed Method
              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='teaser.img'
                  src={require('../../assets/repose/teaser.png')}
                />
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Quantitative Result
              </Typography>
              <Grid item>
                <Typography className={classes.caption}>
                  Result on the LineMOD dataset
                </Typography>
                <img
                  className={classes.imageStyle}
                  alt='linemod.img'
                  src={require('../../assets/repose/linemod.png')}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.caption}>
                  Result on the Occlusion LineMOD dataset
                </Typography>
                <img
                  className={classes.imageStyle}
                  alt='occ_linemod.img'
                  src={require('../../assets/repose/occ_linemod.png')}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.caption}>
                  Result on the YCB-Video dataset
                </Typography>
                <img
                  className={classes.imageStyle}
                  alt='ycb_video.img'
                  src={require('../../assets/repose/ycb_video.png')}
                />
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Qualitative Result
              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='qualitative_result.img'
                  src={require('../../assets/repose/qualitative_result.png')}
                />
              </Grid>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.subtitle}>
                Citation
              </Typography>
              <div className={classes.citationContainerStyle}>
                <Tooltip title="Copy" placement="left" arrow>
                  <IconButton className={classes.citationCopyButtonStyle} onClick={onClickCopyButton} >
                    <FileCopyOutlinedIcon/>
                  </IconButton>
                </Tooltip>
                <div className={classes.citationStyle}>
                  <pre>
                    {citation}
                  </pre>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(RePOSEContainer);

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import './App.css';
import ScrollToTop from './components/ScrollToTop';
import NavBarContainer from './containers/NavBarContainer';
import NavBarPubContainer from './containers/NavBarPubContainer';
import RePOSEContainer from './containers/papers/RePOSEContainer';
import RelightableHandsContainer from './containers/papers/RelightableHandsContainer';
import OctMAEContainer from './containers/papers/OctMAEContainer';
import AboutContainer from './containers/AboutContainer';
import ContentContainer from './containers/ContentContainer';
import FooterContainer from './containers/FooterContainer';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Noto Sans JP"',
      'sans-serif',
    ].join(','),
  },
});


const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme} >
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/repose">
              <React.Fragment>
                <NavBarPubContainer />
                <RePOSEContainer />
                <FooterContainer />
              </React.Fragment>
            </Route>
            <Route path="/relightable_hands">
              <React.Fragment>
                <NavBarPubContainer />
                <RelightableHandsContainer />
                <FooterContainer />
              </React.Fragment>
            </Route>
            <Route path="/oct_mae">
              <React.Fragment>
                <NavBarPubContainer />
                <OctMAEContainer />
                <FooterContainer />
              </React.Fragment>
            </Route>
            <Route path="/">
              <React.Fragment>
                <NavBarContainer />
                <AboutContainer />
                <ContentContainer />
                <FooterContainer />
              </React.Fragment>
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
